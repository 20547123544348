import './App.css';
import Header from './components/Header/Header.jsx';
import Footer from './components/Footer/Footer.jsx';
import About from './components/About.jsx';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Pricing from './components/Pricing.jsx';
import Home from './components/Home.jsx';
import Contact from './components/Contact.jsx';

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Header />
        <div className="d-flex justify-content-center py-3 pb-auto flex-wrap main-content">
          <Routes>
            <Route path='/' element={<Home />}/>
            <Route path='about' element={<About />}/>
            <Route path='pricing' element={<Pricing />}/>
            <Route path='contact' element={<Contact />}/>
          </Routes>
        </div>
        <Footer />
      </BrowserRouter>
    </div>
  );
}

export default App;
