import './Footer.css';

export default function Footer() {
    return (
        <footer>
            <div id="copyright" className="float-end mx-2">
                <span>&#169; 2024 Gulgoleth Mounts</span>
            </div>
        </footer>
    );
}
