import { useState } from "react";
import { Card, Form } from "react-bootstrap";

export default function Pricing() {
    const [price, setPrice] = useState('$100');

    return (
        <>
            <Card>
                <Card.Body>
                    <Card.Title>Pricing</Card.Title>
                    <Card.Text>
                        <div className="d-flex flex-wrap my-2">
                            <Card style={{ width: '30rem' }}>
                                <Card.Body>
                                    <div className="d-flex flex-column align-items-center p-3 price-box">
                                        <h3>Skull Only</h3>
                                        <h2>$80</h2>
                                        <p>Includes skinning, cleaning, & whitening.</p>
                                    </div>
                                </Card.Body>
                            </Card>
                            <Card style={{ width: '30rem' }}>
                                <Card.Body>
                                    <div className="d-flex flex-column align-items-center p-3 price-box">
                                        <h3>Plaque Mounts</h3>
                                        <Form.Select
                                            className="my-2"
                                            onChange={(e) => {setPrice("$" + e.target.value)}}
                                        >
                                            <option value="100">Pine</option>
                                            <option value="105">Aspen</option>
                                            <option value="110">Poplar</option>
                                            <option value="115">Cedar</option>
                                            <option value="120">Red Oak</option>
                                            <option value="95">Buyer Supplied</option>
                                        </Form.Select>
                                        <h2>{price}</h2>
                                        <p>Includes skinning, cleaning, whitening & plaque mounting. Custom plaque designs may incur additional fees.</p>
                                    </div>
                                </Card.Body>
                            </Card>
                            <Card style={{ width: '30rem' }}>
                                <Card.Body>
                                    <div className="d-flex flex-column align-items-center p-3 price-box">
                                        <h3>Antlers Only</h3>
                                        <h2>$50</h2>
                                        <p>Includes cleaning & mounting. Option of replica skull or velvet cap.</p>
                                    </div>
                                </Card.Body>
                            </Card>
                        </div>
                        <p>A $10 discount will be applied if skull is recieved already skinned.</p>
                        <p>Will accept cash, check, and Venmo.</p>
                        <span><i>Prices subject to change.</i></span>
                    </Card.Text>
                </Card.Body>
            </Card>
        </>
    );
}