import { Card } from "react-bootstrap";
import profileImg from "../assets/profile.jpeg";

export default function About() {
    return (
        <>
            <Card style={{ width: '80rem' }}>
                <Card.Body>
                    <Card.Title>About</Card.Title>
                    <Card.Text>
                        <div className="d-flex justify-content-evenly flex-wrap">
                            <div className="px-5">
                                <p><b>Gulgoleth Mounts</b> was born from my love for hunting and passion for making things. As a hunter, I understand the hard work and dedication that goes into every hunt. I believe that every experience in the field is worth preserving. That’s why I'm dedicated producing high-quality euro mounts, ensuring you have a trophy you can proudly display.</p>
                                <p>Gulgoleth (<i>gul-go'-leth</i>) is a Hebrew word that translates to 'head' or 'skull.' It is the origin of the name Golgotha, which means 'the place of the skull'—the site where Jesus sacrificed his life for us (Mark 15:22).</p>
                            </div>
                            <img className="rounded" src={profileImg} alt="About" height="400px"/>
                        </div>

                    </Card.Text>
                </Card.Body>
            </Card>        
        </>
    );
}