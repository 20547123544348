import imageOne from "../assets/IMG_3958.jpeg";
import imageTwo from "../assets/IMG_3993.jpeg";
import imageThree from "../assets/IMG_4015.jpeg";
import {Carousel, Card, Image} from "react-bootstrap";

export default function Home() {
    return (
        <div className="d-flex flex-column align-items-center">
            <Card style={{ width: '80rem' }} className="home-card">
                <Card.Body>
                    <div className="text-center">
                        <Carousel>
                            <Carousel.Item>
                                <Image src={imageOne} alt="First slide" className="carousel-img" rounded fluid />
                            </Carousel.Item>
                            <Carousel.Item>
                                <Image src={imageTwo} alt="Second slide" className="carousel-img" rounded fluid/>
                            </Carousel.Item>
                            <Carousel.Item>
                                <Image src={imageThree} alt="Third slide" className="carousel-img" rounded fluid/>
                            </Carousel.Item>
                        </Carousel>
                    </div>
                    <h4 className="mt-4 text-center"><i>Mid-Missouri European Mount Services</i></h4>
                </Card.Body>
            </Card>
        </div>
    );
}