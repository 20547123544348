import logo from '../../assets/logo192.png';
import './Header.css';
import { NavLink } from 'react-router-dom';
import Dropdown from 'react-bootstrap/Dropdown';

export default function Header() {

    return (
        <header>
            <nav data-bs-theme="dark" className='navigation'>
                <div>
                    <NavLink to='/'><img src={logo} alt="Gulgoleth Mounts" height="100vh"/>Gulgoleth Mounts</NavLink>
                </div>
                <div className='menu-large mx-4'>
                    <NavLink to="/pricing">Pricing</NavLink>
                    <NavLink to="/about">About</NavLink>
                    <NavLink to="/contact">Contact</NavLink>
                </div>
                <div className='menu-small mx-4'>
                    <Dropdown size="lg">
                        <Dropdown.Toggle variant="dark" id="dropdown-basic">
                            &#9776;
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                            <Dropdown.Item><NavLink to="/pricing">Pricing</NavLink></Dropdown.Item>
                            <Dropdown.Item><NavLink to="/about">About</NavLink></Dropdown.Item>
                            <Dropdown.Item><NavLink to="/contact">Contact</NavLink></Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                </div>
            </nav>
        </header>
    );
}